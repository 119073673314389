import { useState, useEffect } from 'react';
import axios from 'axios';

const useLiveStats = () => {
  const [liveStats, setLiveStats] = useState({
    donationCount: 0,
    totalAmount: 0,
    avgAmount: 0,
    medianAmount: 0,
    largestDonation: 0,
    subscriptionCount: 0,
    jummahCount: 0,
    donorCount: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('https://donate.givemasjid.com/stats/web');
        const data = response.data;
        setLiveStats({
          donationCount: data.count,
          totalAmount: data.totalAmount,
          avgAmount: data.avgAmount,
          medianAmount: data.medianAmount,
          largestDonation: data.largestDonation,
          subscriptionCount: data.subscriptionCount,
          jummahCount: data.jummahCount,
          donorCount: data.uniqueCustomerCount

        });
      } catch (error) {
        console.error('Error fetching donation stats:', error);
      }
    };

    fetchStats();
  }, []);

  return liveStats;
};

export default useLiveStats;
