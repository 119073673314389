import {
    CreditCardIcon,
  } from '@heroicons/react/outline'

  import YouTube from "./Youtube.jsx"
 
  
//   const features = [
//     {
//       name: 'Push to deploy.',
//       description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'SSL certificates.',
//       description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'Simple queues.',
//       description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'Advanced security.',
//       description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'Powerful API.',
//       description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
//       icon: CreditCardIcon,
//     },
//     {
//       name: 'Database backups.',
//       description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. ',
//       icon: CreditCardIcon,
//     },
//   ]
  
  export default function Video() {
    return (
      <div className="bg-gray-900 py-24 sm:py-24" id="video">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto sm:text-center">
            {/* <h2 className="text-base font-semibold leading-7 text-indigo-400">Video</h2> */}
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl"><span className="text-emerald-500">Why </span> GiveMasjid?</p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Hear from our founder Ahmed about why we started GiveMasjid and why it might be the right donation solution for your masjid. 
            </p>
          </div>
        </div>
        <div className="relative pt-16 flex justify-center"> {/* Updated line */}
            <div className="mx-auto px-6 lg:px-8 w-full lg:w-3/4">
              <YouTube embedId="OJ1DdnSA50k?si=sm9YQIvBQTYIlv2U" />
            </div>
      </div>
      </div>
    )
  }
  