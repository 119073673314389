// import Image from 'next/future/image'

import { Container } from './Container.jsx'

const testimonials = [
  [
    {
      content:
        "Our masjid still collects all of its donations in a small box. I never carry cash —  which means I usually won't donate (even when I want to). GiveMasjid would be a huge help.",
      author: {
        name: 'Ahmed H.',
        role: 'New York, NY',
      },
    },
  ],
  [
    {
      content:
        'Using GiveMasjid was super easy. It took me less than 30 seconds to make a donation. I wish more masjids had something like this.',
      author: {
        name: 'Ibrahim T.',
        role: 'Houston, TX',
      },
    },
  ],
  [
    {
      content:
        "I want to support my masjid —  and being able to make a credit card donation during Jummah makes it way more likely I'll give a larger donation.",
      author: {
        name: 'Sarah R.',
        role: 'Denver, CO',
      },
    },
  ],
  [
    {
      content:
        "I love the fact that GiveMasjid makes it easy for me to see the masjid's fundraising goal. It makes me feel like I'm truly contributing to something real.",
      author: {
        name: 'Abdul-Rahman, H',
        role: 'Salt Lake City, UT',
      },
    },
  ],
  [
    {
      content:
        "At my mosque, we pass around a box when it comes time to collect donations. It feels awkward and disorganized. I'd much rather donate through my phone with GiveMasjid.",
      author: {
        name: 'Leila, B',
        role: 'Miami, FL',
      },
    },
  ],
  [ 
    {
      content:
        "I love the look and feel of the GiveMasjid donation experience. It feels like a huge upgrade from what I'm used to seeing at masjids.",
      author: {
        name: 'Khadijah, R',
        role: 'Jersey City, NJ',
      },
    },
  ]
]

function QuoteIcon(props) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  )
}

export default function UserTestimonials() {
  return (
    <section
      aria-label="What our customers are saying"
      className="bg-white pt-20 pb-12 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center" >
          <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Loved<span className="text-indigo-600"> by Donors Like Yours</span>.
          </h3>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Hear from donors who have used our platform. With GiveMasjid you can offer <em>your</em> community a better donation experience. 
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          {testimonial.content}
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            {testimonial.author.name}
                          </div>
                          <div className="mt-1 text-sm text-slate-500">
                            {testimonial.author.role}
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
