import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FomoNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);


  const apiUrl = 'https://donate.givemasjid.com/stats/fomo';

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(apiUrl);
        const filteredNotifications = response.data.filter(notification => 
          notification.donation !== null && 
          notification.daysAgo !== null
        );
        setNotifications(filteredNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      let index = 0;
      let timeout = null;
      let hideTimeout = null;
  
      const showNextNotification = () => {
        if (index < notifications.length) {
          setCurrentNotification(notifications[index]);
          setIsVisible(true);
          setNotificationCount(notificationCount + 1);
  
          // Clear the previous hideTimeout
          clearTimeout(hideTimeout);
  
          hideTimeout = setTimeout(() => {
            setIsVisible(false);
            index++;
            timeout = setTimeout(showNextNotification, 10000); // Wait 10 seconds before showing the next notification
          }, 10000); // Hide the notification after 10 seconds
        }
      };
  
      timeout = setTimeout(showNextNotification, 6000);
  
      return () => {
        clearTimeout(timeout);
        clearTimeout(hideTimeout);
      };
    }
  }, [notifications]);
  

  if (!currentNotification) return null;

  const formattedDonation = currentNotification.donation.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div className={`fixed bottom-16 right-4 bg-white shadow-lg rounded-lg p-4 max-w-sm ${isVisible ? 'animate-fade-in-out' : 'opacity-0'}`}>
      <p className="text-sm">
        A GiveMasjid masjid just received <span className="font-semibold">${formattedDonation}.</span> <span className="text-xs text:gray-300"> {currentNotification.daysAgo} days ago</span>
      </p>

      {/* <p className="text-sm">
        A GiveMasjid donor from {currentNotification.city}, {currentNotification.state} just contributed <span className="font-semibold">${formattedDonation}</span><span className="text-xs text:gray-300">. {currentNotification.daysAgo} days ago</span>.
      </p> */}
    </div>
  );
};

export default FomoNotification;