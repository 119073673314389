// src/components/TrialButton.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/outline';

const TrialButton = ({ isVisible }) => {
  return (
    <Link
      to="/request-trial"
      className={`fixed bottom-4 right-4 bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 z-50 flex items-center transition-opacity ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } duration-1000 ease-in-out`}
    >
      <CheckCircleIcon className="h-6 w-6 mr-2" />
      <span>Request a Trial</span>
    </Link>
  );
};

export default TrialButton;