import React, { useState, useEffect } from 'react';

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon, CashIcon, TrendingDownIcon, UserGroupIcon, CreditCardIcon, TrendingUpIcon, PresentationChartBarIcon, KeyIcon, CurrencyDollarIcon, HeartIcon, GiftIcon, MoonIcon, PresentationChartLineIcon, QrcodeIcon, QuestionMarkCircleIcon, ClockIcon, ChevronDoubleUpIcon } from '@heroicons/react/outline'
import box1 from '../assets/Box1.png';
import box2 from '../assets/Box2.png';
import box3 from '../assets/Box3.png';
import box4 from '../assets/Box4.png';

import boxes from '../assets/Boxes.gif';
import app from '../assets/App1.png';
import app2 from '../assets/App2.png';
import jummah from '../assets/jummah.png';
import appvid from '../assets/AppVid.gif';
import flyer from '../assets/flyer_shot.png';
import flyer_app2 from '../assets/Flyer_App_Combo_2.png';
import flyer_app from '../assets/Flyer_App_Combo.png';
import qrgirl from '../assets/GirlQR.png';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { HashLink } from 'react-router-hash-link';


import { Fade, Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';


const images = [box1, box2, box3, box4];



const transferFeatures = [
  {
    id: 1,
    name: "Your Donors Don't Carry Cash",
    description:
      "Estimates suggest that just 16% of people still carry cash on hand -- and even when they do, on average they carry less than $50. If your masjid still receives most of its donations in cash — your average donation is likely 2x smaller than what it could be.",
    icon: CashIcon,
  },
  {
    id: 3,
    name: "Your Donors Don't Trust Cash Boxes",
    description:
      "When donors see donation boxes passed around at masjids, they lose trust in the masjid's use of funding. Since donors can't be clear on the masjid's financial goals and how much has already been raised, they assume the masjid has already collected enough and hold back on large-sum donations. ",
    icon: QuestionMarkCircleIcon,
  },
  {
    id: 2,
    name: "Your Donors Can't Be Identified",
    description:
      'When donors contribute through cash, your masjid has no way of identifying its biggest donors. Your masjid loses access to the hidden champions in your community, who are often willing to offer more if directly asked.',
    icon: UserGroupIcon,
  }
]
const communicationFeatures = [

  {
    id: 4,
    name: 'Offer the Best Donation Experience',
    description:
      "Through GiveMasjid, your masjid will have its very own fast, frictionless, and easy-to-use donation system. No more clunky, confusing, or dated interfaces. No app download required. All major payment methods accepted (Card, ACH, Apple Pay, Google Pay, Shopify Shop, Cash App, and More). It looks great, works smoothly, and is perfect for both in-person and online donations.",
    icon: CreditCardIcon,
  },
  {
    id: 10,
    name: 'Make It Simple: Donations in Seconds',
    description:
      "Faster donations mean more donations. GiveMasjid's donation pages are optimized for speed and simplicity. In just a few taps -- donors can make a donation in less than 30 seconds. And because it includes Apple Pay and Google Pay, there's no need to search for your credit card. ",
    icon: LightningBoltIcon,
  },

  {
    id: 11,
    name: "Convenient In-Masjid Giving",
    description:
      "GiveMasjid provides you with a bundle of great looking posters and flyers all linked to your masjid's new donation page. Donors scan the QR code and are taken directly to your masjid's donation page. No need for costly credit card terminals. No crowding around cash collection boxes.",
    icon: QrcodeIcon,
  },

  {
    id: 12,
    name: "Motivate 5x Larger Donations",
    description:
      "The average GiveMasjid donation is 5x larger than the typical cash donation. When donors can use the payment option of their choice and donate through a modern platform, they routinely give more than when they are limited to cash or a single payment option. Offer your community a better donation system and watch your donations grow.",
    icon: ChevronDoubleUpIcon,
  }
]


export default function Platform() {
  const [expandedFeatures, setExpandedFeatures] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 3000); // Change the interval duration (in milliseconds) as desired

    return () => clearInterval(interval);
  }, [currentImageIndex, images.length]);

  const toggleFeature = (id) => {
    setExpandedFeatures(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <div className="bg-white overflow-hidden sm:py-24 md:py-6">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        {/* <div className="relative">
          <h2 className=" text-2xl leading-10 font-extrabold tracking-tight text-gray-900 sm:text-4xl md:text-3xl lg:text-4xl sm:pt-12 md:pt-2">
                <span className="block inline">Is Your Masjid Looking to </span>{' '}
                <span className="block inline text-indigo-600">Grow</span>?
          </h2>
          <p className="mt-4 max-w-3xl text-xl text-gray-500">
            Then it may be time to re-think how you collect and manage your donations.
          </p>
        </div> */}

        {/* Problem */}
        <div className="relative mt-3 lg:mt-5 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center pt-3" >
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              The Masjid Donation Box.
            </h3>
            <p className="mt-3 sm:text-lg text-gray-500">
              Most masjids collect donations through unloved, back-corner cash collection bins and unsophisticated payment systems. If this sounds like your masjid, you might be missing out on hundreds of thousands of dollars in lost potential donations. <b>Why?</b>
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative mt-6">
                  <div
                    className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-all duration-300 ease-in-out"
                    onClick={() => toggleFeature(item.id)}
                  >
                    <div className="px-4 py-5 sm:p-6">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                              <item.icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                          </div>
                          <div className="ml-4">
                            {/* Updated styling for the headline */}
                            <h3 className="text-lg lg:text-xl font-semibold text-gray-900 leading-6">{item.name}</h3>
                          </div>
                        </div>
                        {expandedFeatures[item.id] ? (
                          <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </div>
                    </div>
                    <div
                      className={`overflow-hidden transition-all duration-300 ease-in-out ${expandedFeatures[item.id] ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                        }`}
                    >
                      <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                        <p className="text-base text-gray-500">{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            {/* <img
              className="relative mx-auto sm:rounded-xl"
              width={550}
              src={boxes}
              alt=""
            /> */}

            <div className="slide-container mx-auto">
              <Slide
                autoplaypauseOnHover={false}
                autoplay={true}
                canSwipe={true}
                indicators={false} 
                arrows={true} 
                duration={2500}
                infinite={true}
                transitionDuration={700}
                easing={"ease"}
              >
                {images.map((image, index) => (
                  <div className="each-slide flex justify-center" key={index}>
                    <div
                      className="slide-container"
                      style={{
                        width: '550px', // Adjust the width as needed
                        height: 'auto', // The height will be automatically adjusted
                      }}
                    >
                      <img className="sm:rounded-xl mx-auto lg:mt-5" src={image} alt={`Slide ${index}`} />
                    </div>
                  </div>
                ))}
              </Slide>
            </div>
          </div>
        </div>

        {/* Graphic */}
        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        {/* Feature 1 */}
        <div className="relative mt-12 sm:mt-16 lg:mt-18" id="platform">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="pt-10 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Your Masjid Deserves <span className="text-indigo-600">Better.</span></h3>
              <p className="mt-3 text-lg text-gray-500">
                With GiveMasjid, you can offer your community a better donation experience — one that inspires confidence and incentivizes them to give generously.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative mt-6">
                    <div
                      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-all duration-300 ease-in-out"
                      onClick={() => toggleFeature(item.id)}
                    >
                      <div className="px-4 py-5 sm:p-6">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                <item.icon className="h-6 w-6" aria-hidden="true" />
                              </div>
                            </div>
                            <div className="ml-4">
                              <h3 className="text-xl font-semibold text-gray-900 leading-6">{item.name}</h3>
                            </div>
                          </div>
                          {expandedFeatures[item.id] ? (
                            <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                          ) : (
                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                          )}
                        </div>
                      </div>
                      <div
                        className={`overflow-hidden transition-all duration-300 ease-in-out ${expandedFeatures[item.id] ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                          }`}
                      >
                        <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                          <p className="text-base text-gray-500">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </dl>
              <div className="text-center mt-10">
                {/* <div className="mt-3 sm:mt-0">
                    <HashLink
                      smooth to="/#loved-by-donors"
                      className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 border-slate-300 border-3  hover:bg-indigo-600 hover:text-white hover:font-bold"
                    >
                      > See Donor Testimonials
                    </HashLink>
                </div> */}

              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img
                className="relative mx-auto rounded-3xl"
                width={600}
                src={flyer_app}
                alt=""
              />
            </div>
          </div>
        </div>

        {/* Feature 2 */}
        {/* <div className="relative mt-8 lg:mt-10 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center pt-6">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Built by Muslims For <span className="text-indigo-600">Muslims</span>.
            </h3>
            <p className="mt-3 sm:text-lg text-gray-500">
              As a Muslim run organization, we understand the Muslim community. We've created a whole host of features specifically aligned to encourage Islamic giving.
            </p>

            <dl className="mt-10 space-y-10">
              {features4.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
              <div className="text-center mt-10">
                <div className="mt-3 sm:mt-0">
                    <a
                      href="/request-trial"
                      className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 border-slate-300 border-3 hover:bg-indigo-600 hover:text-white hover:font-bold"
                    >
                      > Request a Trial
                    </a>
                </div>
              </div>
          </div>


          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img
              className="relative mx-auto sm:rounded-3xl"
              width={800}
              src={jummah}
              alt=""
            />
          </div>
        </div> */}

        {/* Graphic */}
        {/* <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg> */}

        {/* Feature 3 */}
        {/* <div className="relative mt-12 sm:mt-16 lg:mt-24" id="platform">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Donation Management Made <span className ="text-indigo-600">Easy.</span></h3>
              <p className="mt-3 text-lg text-gray-500">
                We've built GiveMasjid from the ground-up with masjids in mind. Not only will your donors enjoy the donation experience, but so will your masjid administrators. <b>Why?</b>
              </p>

              <dl className="mt-10 space-y-10">
                {features3.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
              <div className="text-center mt-10">
                <div className="mt-3 sm:mt-0">
                    <HashLink
                      smooth to="/#reach-us"
                      className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 border-slate-300 border-3  hover:bg-indigo-600 hover:text-white hover:font-bold"
                    >
                      > Get In Touch
                    </HashLink>
                </div>

              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img
                className="relative mx-auto rounded-3xl"
                width={500}
                src={app}
                alt=""
              />
            </div>
          </div>
        </div> */}

      </div>
    </div>
  )
}
