/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import {useParams, useSearchParams, useLocation, useNavigate, Navigate} from "react-router-dom";

// Components
import Banner from "../components/banner.jsx"
import Nav from "../components/Nav.jsx"
import Hero from "../components/Hero.jsx"
import Headline from "../components/Headline.jsx"
import Platform from "../components/Platform.jsx"
import Platform2 from "../components/Platform2.jsx"

import Stats from "../components/Stats.jsx"
import Mission from "../components/Mission.jsx"
import Testimonials from "../components/Testimonials.jsx"
import Video from "../components/Video.jsx"
import TestimonialHighlight from "../components/TestimonialHighlight.jsx"
import MasjidTestimonials from "../components/MasjidTestimonials.jsx"
import CTA from "../components/CTA.jsx"
import Footer from "../components/Footer.jsx"

import FomoNotification from "../components/FomoNotification.jsx"
import TrialButton from "../components/TrialButton.jsx"



function Home() {

 // Capture search parameter for the purpose of 
  let location = useLocation();

  // Capture the scroll location
  const [showTrialButton, setShowTrialButton] = useState(false);
  const [isTrialButtonVisible, setIsTrialButtonVisible] = useState(false);
  const scrollThresholdTop = 500; // Adjust this value to your desired scroll position
  const scrollThresholdBottom = 500; // Adjust this value to your desired scroll position


  // Modal Status
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {

    var hash = location.hash.substring(1); 
    console.log(hash);

    if(hash== ""){
      window.scrollTo(0, 0);
    }

    if(document.getElementById(hash) != null) {
      setTimeout(() => {
        document.getElementById(hash).scrollIntoView();
      }, 250)      
    }

  }, [location]);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollThresholdTop = 500; // Adjust this value to your desired scroll position from the top
      const scrollThresholdFromBottom = 200; // Adjust this value to your desired threshold from the bottom
  
      // Calculate the scroll position relative to the bottom of the page
      const distanceFromBottom = documentHeight - scrollPosition - windowHeight;
  
      const shouldShowButton =
        scrollPosition > scrollThresholdTop &&
        distanceFromBottom > scrollThresholdFromBottom;
  
      setShowTrialButton(shouldShowButton);
  
      if (shouldShowButton && !isTrialButtonVisible) {
        setIsTrialButtonVisible(true);
      } else if (!shouldShowButton && isTrialButtonVisible) {
        setIsTrialButtonVisible(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTrialButtonVisible]);
  

  return (
    <div>
      <Banner />
      {!isModalOpen && <Nav isModalOpen={isModalOpen} />}
      <Hero isModalOpen={isModalOpen} openModal={openModal} closeModal={closeModal} /> 
      <Headline />
      <Platform />
      <Stats />
      {/* <TestimonialHighlight /> */}
      <Platform2 />
      <MasjidTestimonials />
      <Testimonials />
      {/* <MasjidTestimonials /> */}
      <Video />
      <Mission />
      <CTA />
      <Footer />
      {showTrialButton && (
        <>
          <TrialButton isVisible={isTrialButtonVisible} />
          {showTrialButton && <FomoNotification />}
        </>
      )}
    </div>
  );
}

export default Home;
