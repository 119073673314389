export default function Headline() {
    return (
      <div className="bg-gray-800 py-10 sm:py-20"  id="issue">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-4xl">Is Your Masjid Looking to <span className=" text-emerald-500" >Grow</span>?</h2>
            <p className="mt-3 text-lg leading-8 text-gray-300">
                Then it may be time to <i>re-think</i> how you collect and manage your donations.
            </p>
          </div>
        </div>
      </div>
    )
  }