import React, { useRef, useEffect, useState } from 'react';
import useLiveStats from './useLiveStats';
import CountUp from 'react-countup';
import { InView } from 'react-intersection-observer';


const DonationStats = () => {
  const liveStats = useLiveStats();
  const statsRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const formatNumber = (num) => num.toLocaleString('en-US');

  const formatCurrency = (num) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num);

  return (
    <div className="bg-gray-900 py-24 sm:py-32" id="stats">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-4xl">
              Trusted by <span className="text-emerald-500">Masjids</span> and{' '}
              <span className="text-emerald-500">Muslims</span> Everywhere
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Since we first launched the platform in April 2022, we've tried sincerely to help our partner masjids grow.
            </p>
          </div>

          <InView>
            {({ inView, ref }) => (

          <dl 
            ref={ref}
          className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col bg-white/5 p-8">
              <dt className="text-sm font-semibold leading-6 text-gray-300">
                Total Donations Raised
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-emerald-400">
              <CountUp
                  start={isVisible ? 0 : null}
                  end={liveStats?.totalAmount || 0}
                  duration={3}
                  formattingFn={formatCurrency}
                />
              </dd>
            </div>
            <div className="flex flex-col bg-white/5 p-8">
              <dt className="text-sm font-semibold leading-6 text-gray-300">
                Number of Donations Processed
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white">

              {liveStats?.donationCount
                  ? formatNumber(liveStats.donationCount)
                  : '...'}

              </dd>
            </div>
            <div className="flex flex-col bg-white/5 p-8">
              <dt className="text-sm font-semibold leading-6 text-gray-300">
                Average Donation Size
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white">
                {liveStats?.avgAmount
                  ? formatCurrency(liveStats.avgAmount)
                  : '...'}
              </dd>
            </div>
            <div className="flex flex-col bg-white/5 p-8">
              <dt className="text-sm font-semibold leading-6 text-gray-300">
                Total Number of Donors
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white">
                {liveStats?.donorCount
                  ? formatNumber(liveStats.donorCount)
                  : '...'}
              </dd>
            </div>
          </dl>
            )}
            </InView>
        </div>
      </div>
    </div>
  );
};

export default DonationStats;
